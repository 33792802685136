
export default {
    props: {
        url: {
            type: String,
            default: null
        },
        description: {
            type: String,
            default: null
        },
        image: {
            type: Object,
            default: null
        },
        logo: {
            type: Object,
            default: null
        },
        external: {
            type: Boolean,
            default: false,
        }
    }
};
