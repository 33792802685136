
import defaultFieldProps from '~/components/FlexibleSections/Blocks/defaultFieldProps';

export default {
    mixins: [
        defaultFieldProps
    ],
    props: {
        header: {
            type: String,
            default: null
        },
        cases: {
            type: Array,
            default() { return null; }
        },
        background: {
            type: String,
            default: 'default'
        }
    }
};
